import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title

    return (
    <Layout location={location} title={siteTitle}>
      <h1>Contact</h1>
      <Seo title="Contact" />
      <p>You can find me at: <a href="http://www.twitter.com/andrewgyule" target="_blank" rel="noreferrer">Twitter</a> / <a href="https://www.linkedin.com/in/andrewgordonyule/" target="_blank" rel="noreferrer">LinkedIn</a> / <a href="https://github.com/andrewgordonyule" target="_blank" rel="noreferrer">Github</a> / <a rel="me noreferrer" target="_blank" href="https://mastodon.scot/@andrewyule">Mastodon</a>
      </p>
    </Layout>
    )
}
export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`